import React from 'react';
import Layout from '../layouts/Layout';
import CTA from '../components/CTA/CTA';
import JobAlerts from '../components/JobAlerts/JobAlerts';
import arrow from '../images/arrow.svg';
import logoIcon from '../images/logo-icon.png';
import './index.css';
const JobDescription = ({ pageContext }: any) => {
	const menuItems = [
		{
			text: 'Home',
			href: '/',
			active: false
		},
		{
			text: 'About Us',
			href: '/',
			active: false
		},
		{
			text: 'Careers',
			href: '/jobs/',
			active: false
		},
		{
			text: 'Meet the Team',
			href: '/team/',
			active: false
		},
		{
			text: 'Working Here',
			href: '/work-here/',
			active: false
		}
	];
	return (
		<Layout
			menuItems={menuItems}
			title={`${pageContext.title} | Genpak Careers`}
			desc={`${pageContext.title} | Genpak Careers`}
		>
			<section className={'bg-gp-green py-6'}>
				<div className={'container mx-auto px-5'}>
					<a
						href={'/jobs/'}
						className={
							'mb-24 flex max-w-[100px] flex-row font-lato text-white hover:text-white hover:underline'
						}
					>
						<img
							src={arrow}
							width={16}
							className={'mr-3 rotate-180'}
							alt={'Back Arrow.'}
						/>
						Back
					</a>
					<h1
						className={'font-lato text-[40px] font-bold text-white'}
					>
						{pageContext.title}
					</h1>
				</div>
			</section>
			<section
				className={
					'bg-[linear-gradient(0deg, #FFFFFF 0%, #DCE8E3 100%)] px-3 pb-14 md:py-14'
				}
			>
				<div className={'container mx-auto'}>
					<div
						className={
							'mt-6 flex flex-row justify-between max-lg:flex-col-reverse'
						}
					>
						<div
							className={
								'job-description-content flex w-3/4 flex-col max-lg:w-full'
							}
						>
							<h2
								className={
									'mb-6 font-lato text-3xl font-bold text-body underline'
								}
							>
								Job Description
							</h2>
							<p
								dangerouslySetInnerHTML={{
									__html: pageContext.description
								}}
							/>
							<a
								href={pageContext.url}
								className={
									'ApplyButton ApplyButtonBottom mt-6 max-w-[300px]'
								}
								target={'_blank'}
							>
								<button
									className={
										'hover:bg-bt-red flex flex-row items-center rounded bg-gp-green p-3 px-10 text-center text-white hover:bg-gp-green/[0.9]'
									}
								>
									<span className={'font-lato text-xl'}>
										APPLY NOW
									</span>
								</button>
							</a>
						</div>
						<div
							className={
								'flex w-1/4 flex-col px-3 pb-6 max-lg:w-full max-md:px-0'
							}
						>
							<div
								className={
									'mt-6 rounded bg-white p-6 shadow-xl'
								}
							>
								<img
									src={logoIcon}
									width={125}
									className={'mx-auto mb-4'}
									alt={'Genpak Icon'}
								/>
								<span className={'font-lato text-xl font-bold'}>
									Location:
								</span>
								<p className={'pb-6 font-lato text-lg'}>
									{pageContext.city}, {pageContext.state}
								</p>
								<span className={'font-lato text-xl font-bold'}>
									Category:
								</span>
								<p className={'pb-6 font-lato text-lg'}>
									{pageContext.category}
								</p>
								<span className={'font-lato text-xl font-bold'}>
									Req ID:
								</span>
								<p className={'pb-6 font-lato text-lg'}>
									{pageContext.reqNumber}
								</p>
								<a
									href={pageContext.url}
									className={'ApplyButton ApplyButtonTop'}
									target={'_blank'}
								>
									<button
										className={
											'flex w-full flex-row items-center justify-center rounded bg-gp-green p-3 text-center text-white hover:bg-gp-green/[0.9]'
										}
									>
										<span className={'font-lato text-xl'}>
											APPLY NOW
										</span>
									</button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
			<CTA
				h2={'Ready to get started?'}
				p={
					'Genpak is growing and wants you to join our successful team today!'
				}
			/>
			<JobAlerts />
		</Layout>
	);
};

export default JobDescription;
